/* eslint-disable fp/no-let */
/* eslint-disable no-magic-numbers */
import If from 'Component/If';
import ProductGallery from 'Component/ProductGallery';
import TextPlaceholder from 'Component/TextPlaceholder';
import { ProductPageTabs } from 'Route/ProductPage/ProductPage.config';
import {
    ProductPageComponent as SourceProductPageComponent,
} from 'SourceRoute/ProductPage/ProductPage.component';
import { ProductPageTab } from 'SourceRoute/ProductPage/ProductPage.type';
import { ReactElement } from 'Type/Common.type';
import { getProductInStock } from 'Util/Product/Extract';
import { StockCheckProduct } from 'Util/Product/Product.type';

import './ProductPage.override.style';

/** @namespace Pwa/Route/ProductPage/Component */
export class ProductPageComponent extends SourceProductPageComponent {
    tabMap: Record<ProductPageTabs, ProductPageTab> = {
        [ ProductPageTabs.INFORMATION ]: {
            name: __('About'),
            shouldTabRender: (): boolean => {
                const { isInformationTabEmpty } = this.props;

                return !isInformationTabEmpty;
            },
            render: (key: string): ReactElement => this.renderProductInformationTab(key),
        },
        [ ProductPageTabs.ATTRIBUTES ]: {
            name: __('Details'),
            shouldTabRender: (): boolean => {
                const { isAttributesTabEmpty } = this.props;

                return !isAttributesTabEmpty;
            },
            render: (key: string): ReactElement => this.renderProductAttributesTab(key),
        },
        [ ProductPageTabs.REVIEWS ]: {
            name: __('Reviews'),
            // Return false since we never show 'Add review' button
            shouldTabRender: (): boolean => false,
            render: (key: string): ReactElement => this.renderProductReviewsTab(key),
        },
    };

    className = 'ProductActions';

    renderSkuAndStock(): ReactElement {
        return (
            <div block="ProductActions">
                <div block="ProductActions" elem="PriceStock">
                    <div
                      block="ProductActions"
                      elem="Schema"
                    >
                        { this.renderSku() }

                    </div>
                    { this.renderStock() }
                </div>
            </div>
        );
    }

    renderProductBrand(): ReactElement | null {
        const {
            dataSource: {
                attributes: {
                    sub_family: { attribute_options = {}, attribute_value = '' } = {},
                } = {},
            },
        } = this.props;

        const subFamilyLabel = attribute_options?.[attribute_value]?.label;

        if (!subFamilyLabel) {
            return null;
        }

        return (
            <>
             <meta itemProp="brand" content={ subFamilyLabel } />
                <h4 block={ this.className } elem="Brand" itemProp="brand">
                    { subFamilyLabel }
                    <TextPlaceholder content={ subFamilyLabel } />
                </h4>
            </>
        );
    }

    renderStock(): ReactElement {
        const { activeProduct } = this.props;

        const inStock = getProductInStock(activeProduct as Partial<StockCheckProduct>);

        if (inStock) {
            return <span block={ this.className } elem="Stock" mods={ { inStock } }>{ __('In stock') }</span>;
        }

        return <span block={ this.className } elem="Stock">{ __('Out of stock') }</span>;
    }

    renderSku(): ReactElement {
        const { activeProduct } = this.props;
        const { sku } = activeProduct;

        return (
            <span block={ this.className } elem="Sku" itemProp="sku">
                <b>{ __('Cart.code') }</b>
                { ' ' }
                { sku }
            </span>
        );
    }

    renderProductPageContent(): ReactElement {
        const {
            areDetailsLoaded,
            activeProduct,
            activeProduct: { price_range },
            useEmptyGallerySwitcher,
            isVariant,
            isMobile,
        } = this.props;

        let discountPercent = '';

        const regularPriceExclTax = price_range?.minimum_price?.regular_price_excl_tax?.value ?? null;
        const finalPriceExclTax = price_range?.minimum_price?.final_price_excl_tax?.value ?? null;

        if (regularPriceExclTax !== null && finalPriceExclTax !== null) {
            const discount = ((regularPriceExclTax - finalPriceExclTax) / regularPriceExclTax) * 100;

            const discountString = (discount || 0).toString();
            const matchResult = discountString.match(/^-?\d+(\.\d{0,2})?/);

            discountPercent = matchResult ? matchResult[0] : '0';

            if (discountPercent.endsWith('.00')) {
                discountPercent = discountPercent.split('.')[0];
            }
        }

        return (
            <>
                <If condition={ isMobile }>
                    { this.renderProductDesktopMainData() }
                    { this.renderSkuAndStock() }
                </If>
                <ProductGallery
                  product={ activeProduct }
                  areDetailsLoaded={ areDetailsLoaded }
                  // @ts-ignore
                  discountPercent={ discountPercent }
                  isWithEmptySwitcher={ useEmptyGallerySwitcher }
                  showLoader={ isVariant }
                />
                { this.renderProductActions() }
            </>
        );
    }
}

export default ProductPageComponent;
